<template>
  <div>
    <!-- <head-banner :class="is_mobilesize ? 'pb-3' : 'pb-3'" />
    <add-here :class="is_mobilesize ? 'pt-3' : ''" /> -->
    <div class="d-flex justify-content-center col-12 text-center">
      <head-banner />
    </div>
    <div class="d-flex justify-content-center col-12 text-center">
      <add-here />
    </div>
    <!-- <Popup /> -->
    <section id="profile-info">
      <b-row>
        <b-col lg="8" cols="12" order="1" order-lg="1">
          <crypto-details-view
            :coin-data="coinData"
            @callApiAgain="getCoinData"
            @load-page="getCoinData"
          />
          <!-- <AddHere /> -->
          <crypto-comments v-if="!is_mobilesize" :coin-data="coinData" />
        </b-col>

        <b-col lg="4" cols="12" order="2" order-lg="2">
          <crypto-meta-data :coin-data="coinData" :market_cap="market_cap" :has_token="has_token" />
          <crypto-comments v-if="is_mobilesize" :coin-data="coinData" />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import HeadBanner from "@/@core/components/carousel/HeadBanner.vue";
import AddHere from "@/@core/components/AddHere.vue";
import { mixinList } from "@/mixins/mixinList";
import { BCol, BRow } from "bootstrap-vue";
import CryptoDetailsView from "@core/components/crypto/CryptoDetailsView";
import CryptoMetaData from "@core/components/crypto/CryptoMetaData";
import CryptoComments from "@/@core/components/crypto/CryptoComments";

// import Popup from "@/@core/components/carousel/Popup.vue";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    CryptoDetailsView,
    CryptoMetaData,
    CryptoComments,
    AddHere,
    HeadBanner,
    // Popup
  },
  data() {
    return {
      coinData: {},
      has_token: false,
      market_cap: null
    };
  },
  mounted() {
    this.getCoinData();
  },
  methods: {
    getCoinData() {
      let id = this.$route.params.id;
      this.$store.dispatch("FETCH_COIN_DATA", id).then((coinData) => {
        this.coinData = coinData;
        if (coinData.bsc_contract_address) {
          this.$store.dispatch("FETCH_PRICE_DATA", coinData.bsc_contract_address).then((res) => {
                this.market_cap = res.tokenMetadata.tokenMarketCap;
            });
            this.has_token = true
        } else {
          this.has_token = false
          this.market_cap = coinData.actual_market_cap
        }
      });
    },
  },
};
</script>

<style></style>
